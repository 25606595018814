<template>
  <v-container fluid>
    <v-row v-if="isFetching">
      <v-col md="4" v-for="x in 3" :key="x">
        <v-skeleton-loader
          class="mb-6"
          boilerplate
          elevation="2"
          type="table-heading, article, list-item-two-line, actions"
        />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col 
        v-for="(item,index) in items"
        :key="index"
        md="4"
      >
        <QuestionList v-if="item"
        :item="item"
        :index="index"
        :id="questionId"
        :options="optionTypes"
        :doSelectQuestion="doSelectQuestion"
        :doAddChoices="doAddChoices"
        :doUpdateData="doUpdateData"
        :doSave="doSave"
        :doDelete="doDelete"
        :isProcessing="isProcessing"
      />
      </v-col>
      <v-col md="4">
        <v-btn
          class="tw-w-full"
          color="primary"
          @click="doAddQuestion()"
        >
          Add Question
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

// Utilities
import { initComponent } from '@/lib'
import {
  set,
  cloneDeep,
} from 'lodash'

import {
  mapState,
  // mapActions,
  // mapMutations
} from 'vuex'

import {
  UPDATE_SURVEY_STATE,
  REQUEST_QUESTION_LIST,
  REQUEST_ADD_QUESTION,
  REQUEST_DELETE_QUESTION,
} from '@/store/modules/survey'

export default initComponent ('Survey', {
  isPage: true,
  components : {
    QuestionList: () => import('./components/QuestionList'),
  },
  data() {
    return {
      inProgress: false,
      isEdit: false,
      questionId: null,
      optionTypes: [
        {
          text: 'Type - Multiple',
          value: 'multiple',
        },
        {
          text: 'Type - Short Description',
          value: 'short-description',
        }
      ]
    }
  },
  computed: {
    ...mapState({
      //..
      isFetching: (state) => state.survey.isFetching,
      isProcessing: (state) => state.survey.isProcessing,
      items: (state) => state.survey.items,
      paging: (state) => state.survey.paging,
      item: (state) => state.survey.item,
    }),
  },
  methods: {
    async onReady () {
      const { dispatch } = this.$store
      await dispatch(REQUEST_QUESTION_LIST);
    },
    async doSelectQuestion(item) {
      // select the items
      const { commit } = this.$store;

      this.questionId = item.id;
      this.inProgress = true;
      this.isEdit = true;
      commit(UPDATE_SURVEY_STATE, { item : item });
    },
    async doAddQuestion () {
      const { commit, state } = this.$store;
      const { items } = state.survey;

      if (this.inProgress) {
        // show warning current progress will be reset
        alert('Are you sure you want to conitinue ? unsave progress will be deleted');
      } else {
        let id = String(Date.now());
        let item = {
          id: id,
          type : 'multiple',
          choices:[] 
        }
        await commit(UPDATE_SURVEY_STATE, {item : item});
        await commit(UPDATE_SURVEY_STATE, {items : [...items, {...item} ]});
        this.inProgress = true;
        this.isEdit = false;
        this.questionId = id;
      }
    },
    async doAddChoices(item){
      let id = String(Date.now());
      const { commit, state } = this.$store;
      const { items } = state.survey;
      const newArr = items.map(obj => {
        if (obj.id === item.id) {
          let item = {
            ...obj,
            choices: [...obj.choices, {id: id, text: ''}]
          }
          commit(UPDATE_SURVEY_STATE, { item : {...item}});
          return {...item};
        }
        return obj;
      });
      await commit(UPDATE_SURVEY_STATE, { items : newArr});
    },
    async doUpdateData(val, key, index) {
      const { commit, state } = this.$store;
      const { items } = state.survey;
      const item = cloneDeep(state.survey.item);
      if (key === 'choices') {
        let newChoices = item[key].map((obj,idx) => {
          if (idx === index ) {
            return {...obj, text: val}
          }
          return obj;
        });
        set(item, key, newChoices);
      } else {
        set(item, key, val);
      }

      await commit(UPDATE_SURVEY_STATE, { item : item});
      const newArr = items.map(obj => {
        if (obj.id === item.id) {
          return {...item};
        }
        return obj;
      });
      await commit(UPDATE_SURVEY_STATE, { items : newArr});
    },
    async doSave() {
      // select the items
      const { dispatch } = this.$store;
      this.inProgress = false;
      
      if (!this.isEdit) {
        return await dispatch(REQUEST_ADD_QUESTION);
      }

      if (this.isEdit) {
        this.isEdit = false;
      }
      this.questionId = null;
    },
    async doDelete(item) {
      // select the items
      const { dispatch, commit, state } = this.$store;
      const { items } = state.survey;
      this.inProgress = false;

      if (this.isEdit) {
        this.isEdit = !this.isEdit;
        return await dispatch(REQUEST_DELETE_QUESTION);
      }
      
      if (!this.isEdit) {
        let newArr = [...items];
        let toDelete = newArr.findIndex(i => i.id === item.id);
        newArr.splice(toDelete, 1)
        return await commit(UPDATE_SURVEY_STATE, { items : newArr});
      }
      this.questionId = null;
    }
  }
})
</script>
